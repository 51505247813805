<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }"
            >Assessment center</router-link
          >
          | <span class="title"> Bids Submitted</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12">
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <card-title icon="mdi-chat-processing" :is-image="false"
            >Bids Submitted {{ bids.length }}</card-title
          >
          <div v-if="!loading">
            <template v-if="bids.length > 0">
              <v-tabs
                v-model="tab"
                class="tw-my-4 md:tw-my-10"
                active-class="tab-active"
                background-color="primary"
                center-active
                color="warning"
                grow
                dark
                show-arrows
              >
                <v-tabs-slider color="warning" class="tw-p-3"></v-tabs-slider>
                <v-tab>Assessment Report</v-tab>
                <v-tab v-for="(bid, index) in bids" :key="index"
                  >{{ index + 1 }}. {{ scanObject(bid, 'garage.name') }}</v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <garage-bid-template
                    :is-garage="false"
                    :booking="{ biddingForm: biddingTemplate, ...bidInfo }"
                  />
                </v-tab-item>
                <v-tab-item v-for="(b, i) in bids" :key="i">
                  <garage-bid-template
                    :booking="{
                      ...{
                        ...bidInfo,
                        garage: {
                          id: 2,
                          name: 'Golden Auto Body',
                          email: 'goldenauto16@gmail.com',
                          location: 'Industrial Area, Pembe Str',
                          contact_person: 'Davinder Singh',
                          mobile_number: '0722-356869',
                          rare_and_specialized: true,
                          cluster: {
                            id: 'Z',
                            name: 'CLUSTER Z',
                          },
                        },
                      },
                      ...b,
                    }"
                  />
                </v-tab-item>
              </v-tabs-items>
            </template>
            <div
              v-else
              class="
                tw-py-4
                md:tw-py-14
                tw-flex tw-items-center tw-justify-center tw-flex-col
              "
            >
              <img
                :src="require('@/assets/img/assesment-center/no-car.png')"
                class="tw-h-16 md:tw-h-28"
                alt="no car"
              />
              <p
                class="tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify"
              >
                There’s no Bids Submitted here by garages, please head to the
                <span class="c-blue-text tw-font-bold"
                  >submitted bids section</span
                >
              </p>
              <v-btn
                :to="{ name: 'BidsSubmitted' }"
                class="tw-w-full md:tw-w-1/3"
                color="warning"
                >Submitted Bids</v-btn
              >
            </div>
          </div>
          <div v-else class="tw-w-full tw-flex tw-justify-center tw-py-16">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import axios from 'axios'
import GarageBidTemplate from '@/components/assessment-center/bids-submitted/garageBidTemplate'
export default {
  name: 'GarageBidDetails',
  components: { GarageBidTemplate, CardTitle },
  data(vm) {
    return {
      bids: '',
      loading: true,
      bookingId: vm.$route.params.bookingId,
      tab: null,
      biddingTemplate: null,
      bidInfo: {},
    }
  },
  mounted() {
    this.getBooking()
  },
  methods: {
    getBooking() {
      axios
        .get(`/motor-assessment/api/garages/bid-forms/${this.bookingId}`)
        .then((res) => {
          if (res.data.success && res.data.data === null)
            this.$router.push({ name: 'NotFound' })
          if (!res.data.success) {
            this.$router.push({ name: 'BidsSubmitted' })
          }
          const response = res.data.data
          this.bids = response.garageBiddings
          this.biddingTemplate = response.biddingTemplate
          this.bidInfo = {
            clientInfo: response.clientInfo,
            vehicleInfo: response.vehicleInfo,
            policyInfo: response.policyInfo,
          }
          this.loading = false
        })
    },
  },
}
</script>
