var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"align":"center","justify":"center"}},[_c('v-row',{staticClass:"mx-auto py-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_c('router-link',{attrs:{"to":{ name: 'AssessmentCenter' }}},[_vm._v("Assessment center")]),_vm._v(" | "),_c('span',{staticClass:"title"},[_vm._v(" Bids Submitted")])],1)])],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10",attrs:{"elevation":"2"}},[_c('card-title',{attrs:{"icon":"mdi-chat-processing","is-image":false}},[_vm._v("Bids Submitted "+_vm._s(_vm.bids.length))]),(!_vm.loading)?_c('div',[(_vm.bids.length > 0)?[_c('v-tabs',{staticClass:"tw-my-4 md:tw-my-10",attrs:{"active-class":"tab-active","background-color":"primary","center-active":"","color":"warning","grow":"","dark":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{staticClass:"tw-p-3",attrs:{"color":"warning"}}),_c('v-tab',[_vm._v("Assessment Report")]),_vm._l((_vm.bids),function(bid,index){return _c('v-tab',{key:index},[_vm._v(_vm._s(index + 1)+". "+_vm._s(_vm.scanObject(bid, 'garage.name')))])})],2),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('garage-bid-template',{attrs:{"is-garage":false,"booking":Object.assign({}, {biddingForm: _vm.biddingTemplate}, _vm.bidInfo)}})],1),_vm._l((_vm.bids),function(b,i){return _c('v-tab-item',{key:i},[_c('garage-bid-template',{attrs:{"booking":Object.assign({}, Object.assign({}, _vm.bidInfo,
                      {garage: {
                        id: 2,
                        name: 'Golden Auto Body',
                        email: 'goldenauto16@gmail.com',
                        location: 'Industrial Area, Pembe Str',
                        contact_person: 'Davinder Singh',
                        mobile_number: '0722-356869',
                        rare_and_specialized: true,
                        cluster: {
                          id: 'Z',
                          name: 'CLUSTER Z',
                        },
                      }}),
                    b)}})],1)})],2)]:_c('div',{staticClass:"\n              tw-py-4\n              md:tw-py-14\n              tw-flex tw-items-center tw-justify-center tw-flex-col\n            "},[_c('img',{staticClass:"tw-h-16 md:tw-h-28",attrs:{"src":require('@/assets/img/assesment-center/no-car.png'),"alt":"no car"}}),_c('p',{staticClass:"tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify"},[_vm._v(" There’s no Bids Submitted here by garages, please head to the "),_c('span',{staticClass:"c-blue-text tw-font-bold"},[_vm._v("submitted bids section")])]),_c('v-btn',{staticClass:"tw-w-full md:tw-w-1/3",attrs:{"to":{ name: 'BidsSubmitted' },"color":"warning"}},[_vm._v("Submitted Bids")])],1)],2):_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center tw-py-16"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }